import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { handleShow } from "../hook/Functions";
import Loading from "../components/Loading";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import logo from "../components/images/logo_footer.webp";
import AOS from "aos";

import companyProfileEN from "../components/pdf_portfolio/Profile_EN_compressed.pdf";
import companyProfileAR from "../components/pdf_portfolio/Profile_AR_compressed.pdf";
import { FaCloudDownloadAlt } from "react-icons/fa";

const PortfolioPage = () => {
  const { i18n, t } = useTranslation();
  const [dataPortfolio, setDataPortfolio] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fileUrl, setFileUrl] = useState("");

  const handleShowLoading = () => {
    setLoading(true);
    handleShow("portfolio")
      .then((response) => {
        setDataPortfolio(response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error(error.request.statusText);
      })
      .finally(() => {
        setLoading(false);
        AOS.init({
          duration: 2200,
          once: false,
        });
      });
  };

  useEffect(() => {
    handleShowLoading();

    setFileUrl(i18n.language === "ar" ? companyProfileAR : companyProfileEN);
  }, [i18n.language]);

  const ChangeEN = () => {
    i18n.changeLanguage("en");
  };

  const ChangeAR = () => {
    i18n.changeLanguage("ar");
  };

  return (
    <div dir={i18n.language === "ar" ? "rtl" : "ltr"} className="all_element">
      <a href={fileUrl} target="_blank" className="download_company_profile">
        {t("download_company_profile")} <FaCloudDownloadAlt />
      </a>

      <div className="logo portfolio_page">
        <div className="all">
          <Link to="/">
            <img loading="lazy" src={logo} alt="logo" />
          </Link>

          {i18n.language === "ar" ? (
            <div className="changeLang" onClick={ChangeEN}>
              EN
            </div>
          ) : (
            <div className="changeLang" onClick={ChangeAR}>
              AR
            </div>
          )}
        </div>
      </div>

      <section id="portfolio">
        <div className="text animate-element" data-aos="fade-up">
          <h2>{t("portfolio")}</h2>
          <p>{t("info_portfolio")}</p>
        </div>

        <div className="data_portfolio">
          {loading ? (
            <Loading />
          ) : dataPortfolio && dataPortfolio.length > 0 ? (
            dataPortfolio.map((item, i) => {
              const contentDisc =
                i18n.language === "ar"
                  ? item.description_ar
                  : item.description_en;

              return (
                <div
                  className="portfolio animate-element"
                  data-aos="fade-up"
                  key={i}
                >
                  <div className="image">
                    <img loading="lazy" src={item.image} alt={item.title_ar} />
                  </div>
                  <div className="portfolio_description">
                    <h2>
                      {i18n.language === "ar" ? item.title_ar : item.title_en}
                    </h2>
                    <p>{contentDisc}</p>
                  </div>
                </div>
              );
            })
          ) : (
            ""
          )}
        </div>
      </section>
      <Footer PortfolioPage={false} />
    </div>
  );
};

export default PortfolioPage;
