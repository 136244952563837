import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "../components/Loading";

const ProviderRoutsAdmin = ({ children }) => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;

  const handleSubmit = () => {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    axios
      .get(`${LinkLikeBachend}check-auth`, { headers })
      .then((response) => {
        if (response.data.authenticated === true) {
          navigate("/dashboard");
        } else {
          localStorage.removeItem("token");
          navigate("/admin");
        }
        setLoading(true);
      })
      .catch((error) => {
        // console.error("Error:", error);
        localStorage.removeItem("token");
        navigate("/admin");
        setLoading(true);
      });
  };

  useEffect(() => {
    if (token) {
      handleSubmit();
    } else {
      localStorage.removeItem("token");
      navigate("/admin");
      setLoading(true);
    }
  }, []);

  return loading ? children : <Loading />;
};

export default ProviderRoutsAdmin;
