import React, { useEffect, useRef } from "react";
import { handleShow } from "./../../hook/Functions";
import { toast } from "react-toastify";
import Chart from "chart.js/auto";

const Dashboard = () => {
  const handleShowLoading = () => {
    handleShow("count-data")
      .then((response) => {
        const ctx = chartRef.current.getContext("2d");
        new Chart(ctx, {
          type: "pie",
          data: {
            labels: ["portfolio", "slider", "social media"],
            datasets: [
              {
                backgroundColor: ["green", "blue", "orange", "brown"],
                data: [
                  response.data.portfolio_count,
                  response.data.slider_count,
                  response.data.social_media_count,
                ],
              },
            ],
          },
          options: {
            plugins: {
              legend: { display: true },
              tooltip: {
                callbacks: {
                  label: (tooltipItem) => {
                    const label = tooltipItem.label || "";
                    const value = tooltipItem.raw || 0;
                    return `${label}: ${value}`;
                  },
                },
              },
            },
          },
        });
        return () => {
          if (chartRef.current.chart) {
            chartRef.current.chart.destroy();
          }
        };
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error(error.request.statusText);
      });
  };

  const chartRef = useRef(null);

  useEffect(() => {
    handleShowLoading();
  }, []);

  return (
    <canvas
      ref={chartRef}
      style={{
        width: "100%",
        maxWidth: "600px",
        height: "100%",
        margin: "auto",
      }}
    ></canvas>
  );
};

export default Dashboard;
