import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { handleShow } from "../hook/Functions";
import Loading from "../components/Loading";
import { Link } from "react-router-dom";

const Portfolio = () => {
  const { i18n, t } = useTranslation();
  const [dataPortfolio, setDataPortfolio] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleShowLoading = () => {
    setLoading(true);
    handleShow("portfolio")
      .then((response) => {
        setDataPortfolio(response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error(error.request.statusText);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    handleShowLoading();
  }, []);

  return (
    <>
      <section id="portfolio">
        <div className="text animate-element" data-aos="fade-up">
          <h2>{t("portfolio")}</h2>
          <p>{t("info_portfolio")}</p>
        </div>

        <div className="data_portfolio">
          {loading ? (
            <Loading />
          ) : dataPortfolio && dataPortfolio.length > 0 ? (
            dataPortfolio.slice(0, 6).map((item, i) => {
              const contentDisc =
                i18n.language === "ar"
                  ? item.description_ar
                  : item.description_en;

              return (
                <div
                  className="portfolio animate-element"
                  data-aos="fade-up"
                  key={i}
                >
                  <div className="image">
                    <img loading="lazy" src={item.image} alt={item.title_ar} />
                  </div>

                  <div className="portfolio_description">
                    <h2>
                      {i18n.language === "ar" ? item.title_ar : item.title_en}
                    </h2>
                    <p>{contentDisc}</p>
                  </div>
                </div>
              );
            })
          ) : (
            ""
          )}
        </div>

        {dataPortfolio.length > 8 && (
          <Link to="/portfolio" className="contact_us_link">
            {t("show_more")}
          </Link>
        )}
      </section>
    </>
  );
};

export default Portfolio;
