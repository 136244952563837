import React, { useEffect, useState } from "react";
import { handleShow } from "../hook/Functions";
import { toast } from "react-toastify";

const SocialMedia = () => {
  const [dataSocialMedia, setDataSocialMedia] = useState([]);

  const handleShowLoading = () => {
    handleShow("social-media")
      .then((response) => {
        setDataSocialMedia(response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error(error.request.statusText);
      });
  };

  useEffect(() => {
    handleShowLoading();
  }, []);

  return (
    <div className="social_media">
      {dataSocialMedia &&
        dataSocialMedia.length > 0 &&
        dataSocialMedia.map((link, i) => (
          <a href={link.link} target="_blank" key={i}>
            <img loading="lazy" src={link.icon} alt={link.icon} />
          </a>
        ))}
    </div>
  );
};

export default SocialMedia;
