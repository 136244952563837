import React, { useEffect } from "react";
import Slider from "./page/Slider";
import { useTranslation } from "react-i18next";
import Nav from "./page/Nav";
import AboutUs from "./page/AboutUs";
import Culture from "./page/Culture";
import Services from "./page/Services";
import Portfolio from "./page/Portfolio";
import ContactUs from "./page/ContactUs";
import Footer from "./page/Footer";
import AOS from "aos";

const App = () => {
  const { i18n } = useTranslation();
  useEffect(() => {
    AOS.init({
      duration: 2200,
      once: false,
    });
  }, []);

  return (
    <div dir={i18n.language === "ar" ? "rtl" : "ltr"} className="all_element">
      <Slider />
      <Nav />
      <AboutUs />
      <Culture />
      <Services />
      <Portfolio />
      <ContactUs />
      <Footer PortfolioPage={true} />
    </div>
  );
};

export default App;
