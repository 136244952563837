import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-scroll";
import SocialMedia from "./SocialMedia";
import { NavLink } from "react-router-dom";
import logo from "../components/images/logo_footer.webp";

const Nav = () => {
  const { t, i18n } = useTranslation();
  const [showLang, setShowLang] = useState(false);

  const ChangeEN = () => {
    i18n.changeLanguage("en");
  };

  const ChangeAR = () => {
    i18n.changeLanguage("ar");
  };

  const links = [
    { link: "home", text: t("home") },
    { link: "about_us", text: t("about_us") },
    { link: "services", text: t("services") },
    { link: "portfolio", text: t("portfolio") },
    { link: "contact_us", text: t("contact_us") },
  ];

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    setShowLang(scrollTop > 200);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav>
      <div className="language_social_media">
        <div className="logo" style={{ display: showLang ? "flex" : "none" }}>
          <NavLink to="/">
            <img loading="lazy" src={logo} alt="logo" />
          </NavLink>

          {i18n.language === "ar" ? (
            <div className="changeLang" onClick={ChangeEN}>
              EN
            </div>
          ) : (
            <div className="changeLang" onClick={ChangeAR}>
              AR
            </div>
          )}
        </div>

        <div className="all_links">
          <div className="links">
            {links.map((link, i) => (
              <Link
                key={i}
                activeClass="active_nav"
                to={link.link}
                spy={true}
                smooth={true}
                offset={-50}
                duration={2000}
              >
                {link.text}
              </Link>
            ))}
          </div>
          <SocialMedia />
        </div>
      </div>
    </nav>
  );
};

export default Nav;
