import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-scroll";
import logo from "../components/images/logo_footer.webp";
import SocialMedia from "./SocialMedia";
import { NavLink } from "react-router-dom";
import { FaPhoneAlt } from "react-icons/fa";
import { IoMailOpenOutline } from "react-icons/io5";

const Footer = ({ PortfolioPage }) => {
  const { t } = useTranslation();

  const links = [
    { link: "home", text: t("home") },
    { link: "about_us", text: t("about_us") },
    { link: "services", text: t("services") },
    { link: "portfolio", text: t("portfolio") },
    { link: "contact_us", text: t("contact_us") },
  ];

  return (
    <div className="footer">
      <NavLink to="/">
        <img loading="lazy" src={logo} alt="logo" className="logo" />
      </NavLink>
      <section>
        <nav>
          {PortfolioPage ? (
            <div className="links">
              {links.map((link, i) => (
                <Link
                  key={i}
                  to={link.link}
                  spy={true}
                  smooth={true}
                  offset={-50}
                  duration={2000}
                >
                  {link.text}
                </Link>
              ))}
            </div>
          ) : (
            <div className="links">
              <NavLink to="/">{t("home")}</NavLink>
            </div>
          )}

          <div className="all_tel_mailto">
            <div className="tel_mailto">
              <a href="tel:+97433322812">
                <FaPhoneAlt /> 97433322812
              </a>

              <a href="mailto:Hello@lusailstudios.com">
                <IoMailOpenOutline />
                Hello@lusailstudios.com
              </a>
            </div>
            <SocialMedia />
          </div>
        </nav>
      </section>
    </div>
  );
};

export default Footer;
