import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { handleSingel, handleUpdate } from "../../../hook/Functions";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../../components/Loading";

const UpdateTeamAdmin = () => {
  const [loading, setLoading] = useState(false);
  const { idteam } = useParams();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    img: "",
    link_1: "",
    link_2: "",
    link_3: "",
    link_4: "",
    h2_en: "",
    h2_ar: "",
    p_1_en: "",
    p_1_ar: "",
    p_2_en: "",
    p_2_ar: "",
  });

  const handleShow = () => {
    setLoading(true);
    handleSingel("team", idteam)
      .then((response) => {
        setFormData({
          img: response.data.img,
          link_1: response.data.link_1,
          link_2: response.data.link_2,
          link_3: response.data.link_3,
          link_4: response.data.link_4,
          h2_en: response.data.h2_en,
          h2_ar: response.data.h2_ar,
          p_1_en: response.data.p_1_en,
          p_1_ar: response.data.p_1_ar,
          p_2_en: response.data.p_2_en,
          p_2_ar: response.data.p_2_ar,
        });
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error(error.request.statusText);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    handleShow();
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    handleUpdate(e, "team", idteam)
      .then((response) => {
        if (response.data.success) {
          toast.success(response.data.success);
          navigate(`/dashboard/all-team`);
        } else {
          toast.error(response.request.statusText);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error(error.request.statusText);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="form-container" style={{ maxWidth: "100%" }}>
      <div className="logo-container">Update team</div>

      {loading ? (
        <Loading />
      ) : (
        <form className="form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="img_Portfolio">img team</label>
            <input type="file" id="img_Portfolio" name="img" />
          </div>

          <div className="form-group">
            <img
              loading="lazy"
              src={formData.img}
              alt={formData.title_en}
              style={{ maxWidth: "300px" }}
            />
          </div>

          <div className="form-group">
            <label htmlFor="link_1">twitter</label>
            <input
              type="url"
              id="link_1"
              name="link_1"
              placeholder="Enter your twitter Link"
              value={formData.link_1 || ""}
              onChange={handleChange}
            />
            <label htmlFor="link_2">linkedin</label>
            <input
              type="url"
              id="link_2"
              name="link_2"
              placeholder="Enter your linkedin Link"
              value={formData.link_2 || ""}
              onChange={handleChange}
            />
            <label htmlFor="link_3">youtube</label>
            <input
              type="url"
              id="link_3"
              name="link_3"
              placeholder="Enter your youtube Link"
              value={formData.link_3 || ""}
              onChange={handleChange}
            />
            <label htmlFor="link_4">instagram</label>
            <input
              type="url"
              id="link_4"
              name="link_4"
              placeholder="Enter your instagram Link"
              value={formData.link_4 || ""}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="h2_en">Name EN</label>
            <input
              type="text"
              id="h2_en"
              name="h2_en"
              placeholder="Enter your Name EN"
              value={formData.h2_en || ""}
              onChange={handleChange}
            />

            <label htmlFor="h2_ar">Name AR</label>
            <input
              type="text"
              id="h2_ar"
              name="h2_ar"
              placeholder="Enter your Name AR"
              value={formData.h2_ar || ""}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="p_1_en">Founder Description EN</label>
            <input
              type="text"
              id="p_1_en"
              name="p_1_en"
              placeholder="Enter your Founder Description EN"
              value={formData.p_1_en || ""}
              onChange={handleChange}
            />

            <label htmlFor="p_1_ar">Founder Description AR</label>
            <input
              type="text"
              id="p_1_ar"
              name="p_1_ar"
              placeholder="Enter your Founder Description AR"
              value={formData.p_1_ar || ""}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="p_2_en">Job EN</label>
            <input
              type="text"
              id="p_2_en"
              name="p_2_en"
              placeholder="Enter your Job EN"
              value={formData.p_2_en || ""}
              onChange={handleChange}
            />

            <label htmlFor="p_2_ar">Job AR</label>
            <input
              type="text"
              id="p_2_ar"
              name="p_2_ar"
              placeholder="Enter your Job AR"
              value={formData.p_2_ar || ""}
              onChange={handleChange}
            />
          </div>

          <button className="form-submit-btn" type="submit" disabled={loading}>
            submit
          </button>
        </form>
      )}
    </div>
  );
};

export default UpdateTeamAdmin;
