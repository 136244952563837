import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";

import clinte_2 from "../components/images/client/2.webp";
import clinte_3 from "../components/images/client/3.webp";
import clinte_4 from "../components/images/client/4.webp";
import clinte_5 from "../components/images/client/5.webp";
import clinte_6 from "../components/images/client/6.webp";
import clinte_7 from "../components/images/client/7.webp";
import clinte_8 from "../components/images/client/8.webp";
import clinte_9 from "../components/images/client/9.webp";
import clinte_10 from "../components/images/client/10.webp";

import services_1 from "../components/images/services/1.webp";
import services_2 from "../components/images/services/2.webp";
import services_3 from "../components/images/services/3.webp";
import services_4 from "../components/images/services/4.webp";
import services_5 from "../components/images/services/5.webp";
import services_6 from "../components/images/services/6.webp";
import services_7 from "../components/images/services/7.webp";
import services_8 from "../components/images/services/8.webp";
import services_9 from "../components/images/services/9.webp";
import services_10 from "../components/images/services/10.webp";
import services_11 from "../components/images/services/11.webp";
import services_12 from "../components/images/services/12.webp";
import services_13 from "../components/images/services/13.webp";
import services_14 from "../components/images/services/14.webp";
import services_15 from "../components/images/services/15.webp";
import services_16 from "../components/images/services/16.webp";
import services_17 from "../components/images/services/17.webp";

import icon_1 from "../components/images/services/icon/1.png";
import icon_2 from "../components/images/services/icon/2.png";
import icon_3 from "../components/images/services/icon/3.png";
import icon_4 from "../components/images/services/icon/4.png";
import icon_5 from "../components/images/services/icon/5.png";
import icon_6 from "../components/images/services/icon/6.png";
import icon_7 from "../components/images/services/icon/7.png";
import icon_8 from "../components/images/services/icon/8.png";
import icon_9 from "../components/images/services/icon/9.png";
import icon_10 from "../components/images/services/icon/10.png";
import icon_11 from "../components/images/services/icon/11.png";
import icon_12 from "../components/images/services/icon/12.png";
import icon_13 from "../components/images/services/icon/13.png";
import icon_14 from "../components/images/services/icon/14.png";
import icon_15 from "../components/images/services/icon/15.png";
import icon_16 from "../components/images/services/icon/16.png";
import icon_17 from "../components/images/services/icon/17.png";

import { toast } from "react-toastify";
import { IoCloseCircleOutline } from "react-icons/io5";

const Services = () => {
  const { i18n, t } = useTranslation();
  const [selectedItem, setSelectedItem] = useState(null);
  const clinteRef = useRef(null);

  const all_services = [
    {
      id: 1,
      img: services_1,
      icon: icon_1,
      text_en: "TV production",
      text_ar: "انتاج البرامج التلفزيونية",
      description_ar:
        "باختلافها، وعلى الصعد كافَّةً (سياسية، رياضية، اقتصادية، اجتماعية، وثقافية).",
      description_en:
        "In all its forms, and on all levels (political, sports, economic, social, and cultural).",
    },
    {
      id: 2,
      img: services_2,
      icon: icon_2,
      text_en: "Documentary production",
      text_ar: "إنتاج الوثائقيات",
      description_ar:
        "وفق معايير تحريرية وتقنية عالمية؛ تستخدمها كبرى المحطات والمنصَّات الدولية ذات التخصُّص.",
      description_en:
        "According to international editorial and technical standards; used by major international stations and platforms with specialization.",
    },
    {
      id: 3,
      img: services_3,
      icon: icon_3,
      text_en: "Production of promotional advertisements",
      text_ar: "إنتاج الإعلانات الترويجية",
      description_ar: "بما في ذلك الإعلانات الإلكترونية والإذاعية.",
      description_en: "Including electronic and radio advertising.",
    },
    {
      id: 4,
      img: services_4,
      icon: icon_4,
      text_en: "Podcast production",
      text_ar: "إنتاج البودكاست",
      description_ar: "إذ باتت الشركة اليوم تستجيب لمختلف مُتطلَّبات العملاء.",
      description_en:
        "Today, the company responds to various customer requirements.",
    },
    {
      id: 5,
      img: services_5,
      icon: icon_5,
      text_en: "Production of photo reports",
      text_ar: "إنتاج التقارير المصورة",
      description_ar:
        "وفق أفضل أجهزة الصورة والصوت والإضاءة، مع فريق إعدادٍ مُحترف.",
      description_en:
        "With the best image, sound and lighting equipment, and a professional preparation team.",
    },
    {
      id: 6,
      img: services_6,
      icon: icon_6,
      text_en: "Multimedia Content Production",
      text_ar: "إنتاج محتوى الوسائط المتعددة",
      description_ar:
        "بعدما أصبح الإنتاج الرقمي خياراً رئيساً في الوقت الراهن.",
      description_en:
        "Digital production has become a major option at the present time.",
    },
    {
      id: 7,
      img: services_7,
      icon: icon_7,
      text_en: "Short video production",
      text_ar: "إنتاج الفيديوهات القصيرة",
      description_ar:
        "وهي المحتوى الذي ينسجم مع جميع منصَّات التواصل الاجتماعي.",
      description_en:
        "Content that is compatible with all social media platforms.",
    },
    {
      id: 8,
      img: services_8,
      icon: icon_8,
      text_en: "Reality Studio",
      text_ar: "استوديو واقع",
      description_ar:
        "استوديوهان رئيسان A  وB، يحتوي كلٌّ منهما على ديكور خاص به، وذلك عبر تقنية تغيير وتفكيك الديكور- وهي ميزةٌ أُخرى للشركة.",
      description_en:
        "Two main studios A and B, each with its own set, using the technique of changing and dismantling the set - another feature of the company.",
    },
    {
      id: 9,
      img: services_9,
      icon: icon_9,
      text_en: "Visual/Virtual Imagination Studio",
      text_ar: "استوديو الخيال المرئي/ الافتراضي",
      description_ar:
        "وهو استوديو رقمي مُجهَّز بوسائل تسمح بالتفاعل بين الأشخاص أو الأشياء التي يتمّ دمجها مع البيئات المُولِّدة بشكلٍ مناسب في الوقت ذاته.",
      description_en:
        "A digital studio equipped with means to allow interaction between people or objects that are integrated with appropriately generated environments at the same time.",
    },
    {
      id: 10,
      img: services_10,
      icon: icon_10,
      text_en: "Chroma keying",
      text_ar: "خدمة الكروما",
      description_ar:
        "وهي تقنية تستخدم الخلفية الخضراء في التصوير، ليجري إزالتها لاحقاً واستبدالها بمحتوى مرئي آخر، ما يسمح بإنشاء مُؤثّراتٍ بصرية وإضافة خلفياتٍ حسب الغرض.",
      description_en:
        "A technique that uses a green background in photography, which is then removed and replaced with other visual content, allowing for the creation of visual effects and the addition of backgrounds according to the purpose.",
    },
    {
      id: 11,
      img: services_11,
      icon: icon_11,
      text_en: "Live broadcast services",
      text_ar: "خدمات البث المباشر",
      description_ar:
        "عبر منصات التواصل الاجتماعي والمحطات الفضائية، ما يتيح وصولها للجمهور المُتلقّي في حينه، عبر نقل مباشر داخلي من خلال 'غرفة تحكم' PCR مُجهَّزة بأحدث المعدات، لضمان وصول النقل بشكل سريع وعالي الجودة، ونقل مباشر خارجي.",
      description_en:
        "Via social media platforms and satellite stations, which allows them to reach the receiving audience at the time, via an internal live broadcast through a PCR “control room” equipped with the latest equipment, to ensure that the broadcast arrives quickly and with high quality, and an external live broadcast.",
    },
    {
      id: 12,
      img: services_12,
      icon: icon_12,
      text_en: "Audio services",
      text_ar: "الخدمات السمعية",
      description_ar: `إنتاج البرامج الإذاعية: المُسجَّلة والمُباشرة، داخل استوديوهات مُزوَّدة بأحدث تقنيات العزل الصوتي.
إنتاج البودكاست المسموع
إنتاج التعليق الصوتي: مع مراعاة قواعد وأسس الإلقاء الصوتي وفق معايير المؤسسات الإعلامية الوطنية والعالمية.
إنتاج الدوبلاج
إنتاج الخلفيات الموسيقية: للشارات ومقدمات البرامج الاذاعية، وكذلك الموسيقية المرافقة للإنتاجات المسموعة التي تُنتجها الشركة.`,
      description_en: `Production of radio programs: recorded and live, inside studios equipped with the latest sound insulation technologies.
Production of audio podcasts
Production of voice-over: taking into account the rules and foundations of voice-over according to the standards of national and international media institutions.
Dubbing production
Production of background music: for radio program intros and titles, as well as music accompanying the audio productions produced by the company.`,
    },
    {
      id: 13,
      img: services_13,
      icon: icon_13,
      text_en: "Editing services",
      text_ar: "خدمات المونتاج",
      description_ar:
        "تشمل مونتاج البرامج التلفزيونية، الإذاعية، البودكاست، الأفلام التسجيلية والوثائقية، فيديوهات المنصات الرقمية، والإعلانات الترويجية، وذلك في غرف مُجهَّزة تستخدم أحدث البرامج: Adobe premiere pro، Adobe After Effects، وDaVinci resolve.",
      description_en:
        "Includes editing of TV programs, radio programs, podcasts, documentaries, digital platform videos, and promotional advertisements, in equipped rooms using the latest programs: Adobe Premiere Pro, Adobe After Effects, and DaVinci Resolve.",
    },
    {
      id: 14,
      img: services_14,
      icon: icon_14,
      text_en: "Graphics Services",
      text_ar: "خدمات الغرافيكس",
      description_ar:
        "تشمل تصميم العناصر البصرية؛ ودمجها في المحتوى للحصول على إنتاجات متكاملة، فضلاً عن توفير التصميمات المتحركة Animation لإنتاج الأفلام والقصص المصورة وتصميم الهوية البصرية، بالإضافة إلى تصميم Motion graphic. ويتمّ إنجاز هذه الخدمات من خلال برامج: Adobe illustrator 3D Max، Adobe Photoshop، وCima4D.",
      description_en:
        "Includes designing visual elements and integrating them into content to produce integrated productions, in addition to providing animation designs to produce films, comics, and design visual identity, in addition to Motion graphic design. These services are accomplished through the following programs: Adobe Illustrator 3D Max, Adobe Photoshop, and Cima4D.",
    },
    {
      id: 15,
      img: services_15,
      icon: icon_15,
      text_en: "Rental Services",
      text_ar: "خدمات التأجير",
      description_ar: `تُوفر الشركة خدمة تأجير المساحات والمُعدَّات عالية الجودة، مع فريق مُحترف ذي خبرات مُتقدّمة. وتتوزع التجهيزات على النحو التالي:
استوديو A بمساحة 140 متر مربع
استوديو B بمساحة تزيد عن 100 متر مربع
كاميرات
فلاشات
عدسات
إضاءات: مــن شــركة "بروفوتــو" الســويدية الرائــدة فــي تصنيــع مُعـدَّات الإضــاءة.
خلفيات تصوير: حائطية كلاسيكية منقوشة، وأخرى بانورامية مُنحنية، وثالثة ذكية مُتعدِّدة الاستعمالات.
فضلاً عن ذلك، هناك غرفة مكياج، رُكن قهوة مجاني، شبكة واي فاي 5G.`,
      description_en: `The company provides rental services for high-quality spaces and equipment, with a professional team with advanced experience. The equipment is distributed as follows:
Studio A with an area of ​​140 square meters
Studio B with an area of ​​more than 100 square meters
Cameras
Flashes
Lenses
Lights: From the Swedish company "Profoto", a leading manufacturer of lighting equipment.
Photography backgrounds: Classic engraved wall, curved panoramic, and a third smart multi-use.
In addition, there is a makeup room, a free coffee corner, and a 5G Wi-Fi network.`,
    },
    {
      id: 16,
      img: services_16,
      icon: icon_16,
      text_en: "Training Courses",
      text_ar: "الدورات التدريبية",
      description_ar:
        "يُصاحب إنتاجاتنا الفنية خطٌّ أكاديمي مُعاصر يعمل على توفير برامج تدريبية في مجال الإعلام؛ مثل: إنتاج البودكاست المرئي والمسموع، إنتاج الأفلام الوثائقية، التصوير الفوتوغرافي، وغيرها من التخصصات، حيث يتزوَّد المُتدرِّبون بالمهارات الكافية لصنع إنتاجاتٍ إعلامية مميزة يُمكن استخدامها في نقل القصص وتجسيد الأفكار وإبراز المفاهيم بطريقة مُلْهِمة.",
      description_en:
        "Our artistic productions are accompanied by a contemporary academic line that works to provide training programs in the field of media; such as: producing visual and audio podcasts, producing documentary films, photography, and other specializations, where trainees are equipped with the skills necessary to create distinctive media productions that can be used to convey stories, embody ideas, and highlight concepts in an inspiring way.",
    },
    {
      id: 17,
      img: services_17,
      icon: icon_17,
      text_en: "Technical Consulting",
      text_ar: "الاستشارات الفنية",
      description_ar:
        "تُقدِّم الشركة للعملاء- من أفراد وشـركات- خدمات الاستشارات الفنية التقنية؛ ويشـمل ذلـك: استشـارات إنشـاء وتجهيـز استوديوهات التصويـر، اختيــار الكاميــرات والمعــدات المســاعدة المناســبة لطبيعــة النشــاط، استشــارات الإنتاج الإعلامي، وإنتــاج المحتــوى الرقمي لمواقــع ومنصــات التواصل الاجتماعي.",
      description_en:
        "The company provides clients - individuals and companies - with technical consulting services; this includes: consulting on establishing and equipping photography studios, selecting cameras and auxiliary equipment appropriate to the nature of the activity, media production consulting, and producing digital content for social media sites and platforms.",
    },
  ];

  const all_clintes = [
    { img: clinte_2 },
    { img: clinte_3 },
    { img: clinte_4 },
    { img: clinte_5 },
    { img: clinte_6 },
    { img: clinte_7 },
    { img: clinte_8 },
    { img: clinte_9 },
    { img: clinte_10 },
  ];

  const handleItemView = (id) => {
    const item = all_services.find((item) => item.id === id);
    if (item) {
      setSelectedItem(item);
      document.body.style.overflow = "hidden";
    } else {
      toast.error(`Item with id ${id} not found`);
    }
  };

  const handleClose = () => {
    setSelectedItem(null);
    document.body.style.overflow = "";
  };

  return (
    <>
      {selectedItem && (
        <div className="position_fixed">
          <IoCloseCircleOutline className="handleClose" onClick={handleClose} />

          <div
            className="div_position_fixed animate-element"
            data-aos="fade-up"
          >
            <img
              loading="lazy"
              src={selectedItem.img}
              alt={selectedItem.title_ar}
            />

            <div className="item">
              <h2>
                {i18n.language === "ar"
                  ? selectedItem.text_ar
                  : selectedItem.text_en}
              </h2>
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    i18n.language === "ar"
                      ? selectedItem.description_ar
                      : selectedItem.description_en,
                }}
              ></div>
            </div>
          </div>
        </div>
      )}

      <section id="services">
        <div className="text">
          <h2>{t("our_services")}</h2>
          <p>{t("info_our_services")}</p>
        </div>

        <div className="all_services">
          <Swiper
            slidesPerView={4}
            spaceBetween={30}
            loop={true}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            breakpoints={{
              768: {
                slidesPerView: 4,
                spaceBetween: 30,
              },
              0: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
            }}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
          >
            {all_services &&
              all_services.length > 0 &&
              all_services.map((item, i) => (
                <SwiperSlide key={i} virtualIndex={i}>
                  <div
                    className="service"
                    key={i}
                    onClick={() => handleItemView(item.id)}
                  >
                    <div className="count">{`${i + 1}`}</div>
                    <img loading="lazy" src={item.icon} alt={item.text_en} />
                    <p>
                      {i18n.language === "en" ? item.text_en : item.text_ar}
                    </p>
                  </div>
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
      </section>

      <div className="everyone">
        <div className="slider_clients">
          <h2>{t("our_clients")}</h2>

          <div className="all_clintes">
            <Swiper
              ref={clinteRef}
              slidesPerView={5}
              spaceBetween={80}
              loop={true}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
              breakpoints={{
                768: {
                  slidesPerView: 5,
                  spaceBetween: 80,
                },
                0: {
                  slidesPerView: 3,
                  spaceBetween: 50,
                },
              }}
              modules={[Autoplay]}
            >
              {all_clintes &&
                all_clintes.length > 0 &&
                all_clintes.map((image, i) => (
                  <SwiperSlide key={i} virtualIndex={i}>
                    <img
                      loading="lazy"
                      src={image.img}
                      alt={`img slider${i}`}
                      key={i}
                    />
                  </SwiperSlide>
                ))}
            </Swiper>

            <div
              className="swiper-button-next"
              onClick={() => clinteRef.current.swiper.slideNext()}
            ></div>
            <div
              className="swiper-button-prev"
              onClick={() => clinteRef.current.swiper.slidePrev()}
            ></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
