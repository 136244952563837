import axios from "axios";
const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;

export const handleLogout = () => {
  const token = localStorage.getItem("token");
  localStorage.removeItem("token");

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  return axios.get(`${LinkLikeBachend}logout`, { headers });
};

export const handleSubmitAdmin = (e, link) => {
  const formData = new FormData(e.target);

  return axios.post(`${LinkLikeBachend}${link}`, formData);
};

export const handleAdd = (e, link) => {
  const token = localStorage.getItem("token");
  const formData = new FormData(e.target);

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  return axios.post(`${LinkLikeBachend}${link}`, formData, { headers });
};

export const handleDelete = (link, id) => {
  const token = localStorage.getItem("token");

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  return axios.delete(`${LinkLikeBachend}${link}/${id}`, { headers });
};

export const handleShow = (link) => {
  return axios.get(`${LinkLikeBachend}${link}`);
};

export const handleSingel = (link, id) => {
  const token = localStorage.getItem("token");

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  return axios.get(`${LinkLikeBachend}${link}/${id}`, { headers });
};

export const handleUpdate = (e, link, id) => {
  const token = localStorage.getItem("token");
  const formData = new FormData(e.target);

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  return axios.post(`${LinkLikeBachend}${link}/${id}`, formData, { headers });
};
