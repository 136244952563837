export const arabicDataAr = {
  about_us: "نبذة عننا",
  services: "خدمات",
  our_services: "خدماتنا",
  our_clients: "عملائنا",
  portfolio: "ملف الشركة",
  contact_us: "اتصل بنا",
  info_about_us_1:
    "تأسست استوديوهات لوسيل للإنتاج الإعلامي في عام 2014، وسرعان ما برزت كمركز رائد للإنتاج الفني والإعلامي في قطر.",
  info_about_us_2: "وبحلول عام 2021، توسع الاستوديو من خلال شراكة استراتيجية،",
  info_about_us_3:
    "حيث قدم خدمات إنتاج شاملة وأطلق مرافق حديثة لإنتاج الوسائط المرئية والمسموعة والرقمية وتطورت بسرعة لتصبح مركزاً رائداً للإنتاج الفني والإعلامي في قطر.",

  notification_about_1: "الآن!",
  notification_about_2: "استوديو لوسيل",
  notification_about_3:
    "تأسست استوديوهات لوسيل للإنتاج الإعلامي في عام 2014، وتطورت بسرعة لتصبح مركزًا رئيسيًا للإنتاج الفني والإعلامي في قطر.",

  everyone:
    "تؤكد استوديوهات لوسيل على الجودة والاحترافية والابتكار عبر مجموعة واسعة من الخدمات، بما في ذلك إنتاج الفيديو والبث المباشر والخدمات الصوتية وما بعد الإنتاج والتصوير الفوتوغرافي وتغطية الأحداث والتدريب والمزيد .",

  culture: "ثقافتنا",
  culture_p:
    "في جوهرنا، نحن مدفوعون بالابتكار والتعاون والتميز. منذ بداياتنا في تأجير معدات التصوير الفوتوغرافي إلى نمونا الواسع في إنتاج الوسائط، ظلت ثقافتنا تركز على تجاوز الحدود وتقديم نتائج من الدرجة الأولى. نحن نعزز مكان العمل حيث يزدهر الإبداع، ويتم تقدير صوت كل عضو في الفريق.",

  innovate_1_1: "نحن مبتكرون",
  innovate_1_2:
    "العميل مهم جدًا، العميل سيتبعه العميل. قال Maecenas والدعاية في هندريريت. إنها بوابة، يجب فقط ملؤها بالسهام، ليس هناك خوف من عريفي، خوف هندريت هذا هو ديم الحياة. يتلقى الموظف في بعض الأحيان الأمراض. Aenean fringilla دوي عظيم. ويعيش الأطفال مع المرض والشيخوخة والأطفال، ويعانون من الجوع والفقر.",
  innovate_2_1: "نحن مبتكرون",
  innovate_2_2:
    "العميل مهم جدًا، العميل سيتبعه العميل. قال Maecenas والدعاية في هندريريت. إنها بوابة، يجب فقط ملؤها بالسهام، ليس هناك خوف من عريفي، خوف هندريت هذا هو ديم الحياة. يتلقى الموظف في بعض الأحيان الأمراض. Aenean fringilla دوي عظيم. ويعيش الأطفال مع المرض والشيخوخة والأطفال، ويعانون من الجوع والفقر.",

  info_our_services:
    "تأسست الشركة في عام 2014، لتقديم معدات التصوير الفوتوغرافي للإيجار. وفي عام 2021، بدأت الشركة في التوسع، مع افتتاح مقرها الأول حيث قدمت مجموعة متنوعة من الخدمات التي تتراوح من التدريب والاستشارات إلى الإعلانات التلفزيونية وإنتاج البودكاست.",

  info_portfolio:
    "تأسست استوديوهات لوسيل لتكون واحدة من أبرز مراكز إنتاج الوسائط في قطر، حيث قدمت مجموعة شاملة من خدمات الإنتاج التي تلبي احتياجات العملاء في جميع المجالات. مع إطلاق مرافقها الحديثة، استطاعت استوديوهات لوسيل أن تقدم خدمات إنتاج الوسائط المرئية والمسموعة والرقمية على أعلى مستوى.",

  first_name: "الاسم الأول",
  last_name: "اسم العائلة",
  email_address: "عنوان البريد الإلكتروني",
  your_message: "رسالتك",
  enter_your_message: "أدخل سؤالك أو رسالتك",
  send: "ارسال",

  show_more: "عرض المزيد",
  home: "الرئيسية",
  send_message: "تم ارسال رسالتك بنجاح",

  download_company_profile: "تحميل ملف الشركة",
};

export default arabicDataAr;
