import React, { useRef, useState } from "react";
import { toast } from "react-toastify";
import { handleAdd } from "../../../hook/Functions";

const AddSliderAdmin = ({ handleShowLoading }) => {
  const [loading, setLoading] = useState(false);
  const formRef = useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();

    handleAdd(e, "add-slider")
      .then((response) => {
        if (response.data.success) {
          toast.success(response.data.success);
          formRef.current.reset();

          handleShowLoading();
        } else {
          toast.error(response.request.statusText);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error(error.request.statusText);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="form-container">
      <div className="logo-container">Add Slider</div>

      <form className="form" ref={formRef} onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="img_Slider">img Slider</label>
          <input type="file" id="img_Slider" name="image" required />
        </div>

        <div className="form-group">
          <label htmlFor="title_en">title en</label>
          <input
            type="text"
            id="title_en"
            name="title_en"
            placeholder="Enter your title en"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="description_en">description en</label>
          <input
            type="text"
            id="description_en"
            name="description_en"
            placeholder="Enter your description en"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="title_ar">title ar</label>
          <input
            type="text"
            id="title_ar"
            name="title_ar"
            placeholder="Enter your title ar"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="description_ar">description ar</label>
          <input
            type="text"
            id="description_ar"
            name="description_ar"
            placeholder="Enter your description ar"
            required
          />
        </div>

        <button className="form-submit-btn" type="submit" disabled={loading}>
          submit
        </button>
      </form>
    </div>
  );
};

export default AddSliderAdmin;
