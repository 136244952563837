import React, { useState } from "react";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { handleLogout } from "../../hook/Functions";
import { FaWindowClose } from "react-icons/fa";
import { RiMenuFold2Fill } from "react-icons/ri";

const NavDashboard = () => {
  const navigate = useNavigate();
  const [openNav, setOpenNav] = useState(false);
  const handleUserLogout = () => {
    handleLogout()
      .then((response) => {
        if (response.data.message === "Successfully logged out") {
          navigate("/admin");
        }
      })
      .catch((error) => {
        // console.error("Error:", error);
        navigate("/admin");
      });
  };

  const handleOpenNav = () => {
    setOpenNav(!openNav);
  };

  return (
    <>
      <div className="dashboard">
        <RiMenuFold2Fill className="RiMenuFold2Fill" onClick={handleOpenNav} />
        <div
          className="nav_ashboard"
          style={{ left: `${openNav ? "0" : "-100%"}` }}
        >
          <ul onClick={handleOpenNav}>
            <li className="FaWindowClose">
              <FaWindowClose onClick={handleOpenNav} />
            </li>
            <li>
              <a href="/" target="_blank" rel="noopener noreferrer">
                WebSite
              </a>
            </li>
            <li>
              <NavLink to="/dashboard">Dashboard</NavLink>
            </li>

            <li>
              <NavLink to="all-Slider">Slider</NavLink>
            </li>

            <li>
              <NavLink to="/dashboard/all-social-media">social media</NavLink>
            </li>

            <li>
              <NavLink to="/dashboard/all-portfolio">Portfolio</NavLink>

              <ul>
                <li>
                  <NavLink to="/dashboard/add-portfolio">
                    - Add Portfolio
                  </NavLink>
                </li>
              </ul>
            </li>

            <li>
              <NavLink to="/dashboard/all-team">Teams</NavLink>

              <ul>
                <li>
                  <NavLink to="/dashboard/add-team">- Add Team</NavLink>
                </li>
              </ul>
            </li>
          </ul>

          <button className="login" onClick={handleUserLogout}>
            LogOut
          </button>
        </div>

        <section>
          <Outlet />
        </section>
      </div>
    </>
  );
};

export default NavDashboard;
