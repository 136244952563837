export const englishDataEn = {
  about_us: "About us",
  services: "Services",
  our_services: "OUR SERVICES",
  our_clients: "OUR CLIENTS",
  portfolio: "Portfolio",
  contact_us: "Contact us",
  info_about_us_1:
    "Established in 2014, Lusail Media Production Studios quickly emerged as a leading hub for artistic and media production in Qatar.",
  info_about_us_2:
    "By 2021, the studio expanded through a strategic partnership,",
  info_about_us_3:
    "offering comprehensive production services and launching state-of-the-art facilities for visual, audio, and digital media production. has swiftly grown into a premier hub for artistic and media production in Qatar.",

  notification_about_1: "NOW!",
  notification_about_2: "Lusail STUDIO",
  notification_about_3:
    "Established in 2014, Lusail Media Production Studios has rapidly evolved into a major hub for artistic and media production in Qatar.",

  everyone:
    "Guided by the slogan ‘Every detail matters,’ Lusail Studios emphasizes quality, professionalism, and innovation across a wide range of services, including video production, live broadcasting, audio services, post-production, photography, event coverage, training, and more",

  culture: "OUR CULTURE",
  culture_p:
    "At our core, we are driven by innovation, collaboration, and excellence. From our beginnings in photography equipment rentals to our expansive growth in media production, our culture has remained centered on pushing boundaries and delivering top-tier results. We foster a workplace where creativity flourishes, and every team member’s voice is valued. ",

  innovate_1_1: "WE ARE INNOVATIVE",
  innovate_1_2:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas ac augue at erat hendrerit dictum. Praesent porta, purus eget sagittis imperdiet, nulla mi ullamcorper metus, id hendrerit metus diam vitae est. Morbi suscipit interdum molestie. Aenean fringilla dui magna. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.",

  innovate_2_1: "WE ARE INNOVATIVE",
  innovate_2_2:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas ac augue at erat hendrerit dictum. Praesent porta, purus eget sagittis imperdiet, nulla mi ullamcorper metus, id hendrerit metus diam vitae est. Morbi suscipit interdum molestie. Aenean fringilla dui magna. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.",

  info_our_services:
    "It was established back in 2014, providing rental equipements for photography. 2021  kickstarted their expansion, with their first headquarters where they  offered a variety of services ranging from training and consultations toTVC  com- mercials and podcast productions.",

  info_portfolio:
    "Lusail Studios was established as a leading media production center in Qatar, offering a comprehensive range of services to meet diverse client needs. With the launch of modern facilities, Lusail Studios excels in delivering high-quality audiovisual and digital production services.",

  first_name: "First name",
  last_name: "Last name",
  email_address: "Email address",
  your_message: "Your message",
  enter_your_message: "Enter your question or message",
  send: "Send",

  show_more: "Show more",
  home: "Home",
  send_message: "Your message has been sent successfully.",

  download_company_profile: "Download company profile",
};

export default englishDataEn;
