import React, { useEffect, useRef, useState } from "react";
import { handleAdd, handleDelete, handleShow } from "../../../hook/Functions";
import { toast } from "react-toastify";
import { FaWindowClose } from "react-icons/fa";
import Loading from "./../../../components/Loading";

const AllSocialMediasAdmin = () => {
  const [loading, setLoading] = useState(false);
  const formRef = useRef(null);
  const [dataSocialMedia, setDataSocialMedia] = useState([]);

  const handleShowLoading = () => {
    setLoading(true);
    handleShow("social-media")
      .then((response) => {
        setDataSocialMedia(response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error(error.request.statusText);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    handleShowLoading();
  }, []);

  const handleDeleteItem = (id) => {
    setLoading(true);
    handleDelete("destroy-social-media", id)
      .then((response) => {
        if (response.data.success) {
          handleShowLoading();
          toast.success(response.data.success);
        } else {
          toast.error(response.data.error);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error(error.request.statusText);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    handleAdd(e, "add-social-media")
      .then((response) => {
        if (response.data.success) {
          toast.success(response.data.success);
          formRef.current.reset();
          handleShowLoading();
        } else {
          toast.error(response.request.statusText);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error(error.request.statusText);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <div className="form-container">
        <div className="logo-container">Add Social Media</div>

        <form className="form" onSubmit={handleSubmit} ref={formRef}>
          <div className="form-group">
            <label htmlFor="icon">img icon</label>
            <input type="file" id="icon" name="icon" required />
          </div>

          <div className="form-group">
            <label htmlFor="link">link</label>
            <input
              type="url"
              id="link"
              name="link"
              placeholder="Enter your link"
              required
            />
          </div>
          <button className="form-submit-btn" type="submit" disabled={loading}>
            submit
          </button>
        </form>
      </div>

      <div className="all_social_medias_admin">
        {loading ? (
          <Loading />
        ) : dataSocialMedia && dataSocialMedia.length > 0 ? (
          dataSocialMedia.map((item, i) => (
            <div className="social_media_admin" key={i}>
              <FaWindowClose
                disabled={loading}
                onClick={() => handleDeleteItem(item.id)}
              />

              <a href={item.link} target="_blank" rel="noopener noreferrer">
                <img loading="lazy" src={item.icon} alt={item.icon} />
              </a>
            </div>
          ))
        ) : (
          <p>No Data</p>
        )}
      </div>
    </>
  );
};

export default AllSocialMediasAdmin;
