import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { handleAdd } from "./../hook/Functions";
import { toast } from "react-toastify";

const ContactUs = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const formRef = useRef(null);

  const handleMessage = (e) => {
    e.preventDefault();

    handleAdd(e, "add-contact-us")
      .then((response) => {
        if (response.data.success) {
          toast.success(t("send_message"));
          formRef.current.reset();
        } else {
          toast.error(response.request.statusText);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error(error.request.statusText);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div id="contact_us">
      <section>
        <div className="text">
          <h2>{t("contact_us")}</h2>
        </div>

        <form className="form" onSubmit={handleMessage} ref={formRef}>
          <div className="full_name">
            <label htmlFor="first_name">
              {t("first_name")}
              <input
                type="text"
                name="first_name"
                id="first_name"
                placeholder={t("first_name")}
                required
              />
            </label>

            <label htmlFor="last_name">
              {t("last_name")}
              <input
                type="text"
                name="last_name"
                id="last_name"
                placeholder={t("last_name")}
                required
              />
            </label>
          </div>

          <label htmlFor="email_address">
            {t("email_address")}
            <input
              type="email"
              name="email_address"
              id="email_address"
              placeholder={t("email_address")}
              required
            />
          </label>

          <label htmlFor="your_message">
            {t("your_message")}
            <textarea
              name="your_message"
              id="your_message"
              placeholder={t("enter_your_message")}
              required
            ></textarea>
          </label>

          <div className="submit">
            <button type="submit" disabled={loading}>
              {t("send")}
            </button>
          </div>
        </form>
      </section>
    </div>
  );
};

export default ContactUs;
