import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaLinkedin, FaYoutubeSquare, FaInstagramSquare } from "react-icons/fa";
import { Link } from "react-scroll";
import { FaSquareXTwitter } from "react-icons/fa6";
import { handleShow } from "../hook/Functions";
import { toast } from "react-toastify";

const AboutUs = () => {
  const { t, i18n } = useTranslation();

  const [dataAboutUs, setDatateam] = useState([]);

  const handleShowLoading = () => {
    handleShow("team")
      .then((response) => {
        const data =
          window.innerWidth < 768 ? response.data.reverse() : response.data;

        setDatateam(data);
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error(error.request.statusText);
      });
  };

  useEffect(() => {
    handleShowLoading();
  }, []);

  return (
    <>
      <section id="about_us" data-aos="fade-up" className="animate-element">
        <div className="text">
          <h2>{t("about_us")}</h2>
          <p>{t("info_about_us_1")}</p>
          <p>{t("info_about_us_2")}</p>
          <p>{t("info_about_us_3")}</p>
        </div>

        <div className="data_about_us">
          {dataAboutUs.map((item, i) => (
            <div
              key={i}
              className="item_about animate-element"
              data-aos="fade-up"
            >
              <div className="img">
                <img loading="lazy" src={item.img} alt={item.h2_en} />

                <div className="social_media">
                  {item.link_1 != null && (
                    <a href={item.link_1} target="_blank">
                      <FaSquareXTwitter />
                    </a>
                  )}

                  {item.link_2 != null && (
                    <a href={item.link_2} target="_blank">
                      <FaLinkedin />
                    </a>
                  )}

                  {item.link_3 != null && (
                    <a href={item.link_3} target="_blank">
                      <FaYoutubeSquare />
                    </a>
                  )}

                  {item.link_4 != null && (
                    <a href={item.link_4} target="_blank">
                      <FaInstagramSquare />
                    </a>
                  )}
                </div>
              </div>

              <div className="text_about">
                <h2>
                  {(item.h2_en || item.h2_en) && i18n.language == "en"
                    ? item.h2_en
                    : item.h2_ar}
                </h2>
                <p className="p_1">
                  {(item.p_1_en || item.p_1_en) && i18n.language == "en"
                    ? item.p_1_en
                    : item.p_1_ar}
                </p>
                <p className="p_2">
                  {(item.p_2_en || item.p_2_en) && i18n.language == "en"
                    ? item.p_2_en
                    : item.p_2_ar}
                </p>
              </div>
            </div>
          ))}
        </div>

        <div className="notification">
          <div>
            <h2>
              <span>{t("notification_about_1")}</span>
              {t("notification_about_2")}
            </h2>
            <p>{t("notification_about_3")}</p>
          </div>

          <Link
            className="contact_us_link"
            to="contact_us"
            spy={true}
            smooth={true}
            offset={-50}
            duration={2000}
          >
            {t("contact_us")}
          </Link>
        </div>
      </section>

      <div className="everyone">
        <p>{t("everyone")}</p>
      </div>
    </>
  );
};

export default AboutUs;
