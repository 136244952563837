import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { handleSingel, handleUpdate } from "../../../hook/Functions";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../../components/Loading";

const UpdateSliderAdmin = () => {
  const [loading, setLoading] = useState(false);
  const { idSlide } = useParams();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    image: "",
    title_en: "",
    description_en: "",
    title_ar: "",
    description_ar: "",
  });

  const handleShow = () => {
    setLoading(true);
    handleSingel("show-slider", idSlide)
      .then((response) => {
        setFormData({
          image: response.data.image,
          title_en: response.data.title_en,
          description_en: response.data.description_en,
          title_ar: response.data.title_ar,
          description_ar: response.data.description_ar,
        });
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error(error.request.statusText);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    handleShow();
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    handleUpdate(e, "update-slider", idSlide)
      .then((response) => {
        if (response.data.success) {
          toast.success(response.data.success);
          navigate(`/dashboard/all-Slider`);
        } else {
          toast.error(response.request.statusText);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error(error.request.statusText);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="form-container">
      <div className="logo-container">Update Slider</div>

      {loading ? (
        <Loading />
      ) : (
        <form className="form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="img_Slider">img Slider</label>
            <input type="file" id="img_Slider" name="image" />
          </div>

          <div className="form-group">
            <img loading="lazy" src={formData.image} alt={formData.title_en} />
          </div>

          <div className="form-group">
            <label htmlFor="title_en">title en</label>
            <input
              type="text"
              id="title_en"
              name="title_en"
              placeholder="Enter your title en"
              required
              value={formData.title_en}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="description_en">description en</label>
            <input
              type="text"
              id="description_en"
              name="description_en"
              placeholder="Enter your description en"
              required
              value={formData.description_en}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="title_ar">title ar</label>
            <input
              type="text"
              id="title_ar"
              name="title_ar"
              placeholder="Enter your title ar"
              required
              value={formData.title_ar}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="description_ar">description ar</label>
            <input
              type="text"
              id="description_ar"
              name="description_ar"
              placeholder="Enter your description ar"
              required
              value={formData.description_ar}
              onChange={handleChange}
            />
          </div>

          <button className="form-submit-btn" type="submit" disabled={loading}>
            submit
          </button>
        </form>
      )}
    </div>
  );
};

export default UpdateSliderAdmin;
