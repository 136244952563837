import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { handleAdd } from "../../../hook/Functions";
import { toast } from "react-toastify";

const AddTeam = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    handleAdd(e, "team")
      .then((response) => {
        if (response.data.success) {
          toast.success(response.data.success);
          navigate(`/dashboard/all-team`);
        } else {
          toast.error(response.request.statusText);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error(error.request.statusText);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="form-container" style={{ maxWidth: "100%" }}>
      <div className="logo-container">Add Team</div>

      <form className="form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="img">img Team</label>
          <input type="file" id="img" name="img" required />
        </div>

        <div className="form-group">
          <label htmlFor="link_1">twitter</label>
          <input
            type="url"
            id="link_1"
            name="link_1"
            placeholder="Enter your twitter Link"
          />
          <label htmlFor="link_2">linkedin</label>
          <input
            type="url"
            id="link_2"
            name="link_2"
            placeholder="Enter your linkedin Link"
          />
          <label htmlFor="link_3">youtube</label>
          <input
            type="url"
            id="link_3"
            name="link_3"
            placeholder="Enter your youtube Link"
          />
          <label htmlFor="link_4">instagram</label>
          <input
            type="url"
            id="link_4"
            name="link_4"
            placeholder="Enter your instagram Link"
          />
        </div>

        <div className="form-group">
          <label htmlFor="h2_en">Name EN</label>
          <input
            type="text"
            id="h2_en"
            name="h2_en"
            placeholder="Enter your Name EN"
          />

          <label htmlFor="h2_ar">Name AR</label>
          <input
            type="text"
            id="h2_ar"
            name="h2_ar"
            placeholder="Enter your Name AR"
          />
        </div>

        <div className="form-group">
          <label htmlFor="p_1_en">Founder Description EN</label>
          <input
            type="text"
            id="p_1_en"
            name="p_1_en"
            placeholder="Enter your Founder Description EN"
          />

          <label htmlFor="p_1_ar">Founder Description AR</label>
          <input
            type="text"
            id="p_1_ar"
            name="p_1_ar"
            placeholder="Enter your Founder Description AR"
          />
        </div>

        <div className="form-group">
          <label htmlFor="p_2_en">Job EN</label>
          <input
            type="text"
            id="p_2_en"
            name="p_2_en"
            placeholder="Enter your Job EN"
          />

          <label htmlFor="p_2_ar">Job AR</label>
          <input
            type="text"
            id="p_2_ar"
            name="p_2_ar"
            placeholder="Enter your Job AR"
          />
        </div>

        <button className="form-submit-btn" type="submit" disabled={loading}>
          submit
        </button>
      </form>
    </div>
  );
};

export default AddTeam;
