import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "react-toastify/dist/ReactToastify.css";
import "./Lang";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import NotFound from "./components/notFound/NotFound";
import Layouts from "./components/Layouts/Layouts";
import LoginAdmin from "./admin/LoginAdmin";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NavDashboard from "./admin/dashboard/NavDashboard";
import ProviderRoutsAdmin from "./admin/ProviderRoutsAdmin";
import AllSliderAdmin from "./admin/dashboard/sliders/AllSliderAdmin";
import UpdateSliderAdmin from "./admin/dashboard/sliders/UpdateSliderAdmin";
import AllSocialMediasAdmin from "./admin/dashboard/socialMedias/AllSocialMediasAdmin";
import AddPortfolio from "./admin/dashboard/portfolio/AddPortfolio";
import AllPortfolio from "./admin/dashboard/portfolio/AllPortfolio";
import UpdatePortfolioAdmin from "./admin/dashboard/portfolio/UpdatePortfolioAdmin";
import Dashboard from "./admin/dashboard/Dashboard";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/grid";
import PortfolioPage from "./page/PortfolioPage";
import "aos/dist/aos.css";
import AllTeam from "./admin/dashboard/teams/AllTeam";
import AddTeam from "./admin/dashboard/teams/AddTeam";
import UpdateTeamAdmin from "./admin/dashboard/teams/UpdateTeamAdmin";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layouts />,
    errorElement: <NotFound />,
    children: [
      {
        path: "/",
        element: <App />,
      },
      {
        path: "/portfolio",
        element: <PortfolioPage />,
      },
    ],
  },
  {
    path: "admin",
    element: (
      <ProviderRoutsAdmin>
        <LoginAdmin />
      </ProviderRoutsAdmin>
    ),
  },
  {
    path: "dashboard",
    element: (
      <ProviderRoutsAdmin>
        <NavDashboard />
      </ProviderRoutsAdmin>
    ),
    children: [
      {
        path: "/dashboard",
        element: <Dashboard />,
      },
      {
        path: "all-Slider",
        element: <AllSliderAdmin />,
      },
      {
        path: "update-Slider/:idSlide",
        element: <UpdateSliderAdmin />,
      },
      {
        path: "all-social-media",
        element: <AllSocialMediasAdmin />,
      },
      {
        path: "all-portfolio",
        element: <AllPortfolio />,
      },
      {
        path: "add-portfolio",
        element: <AddPortfolio />,
      },
      {
        path: "update-portfolio/:idPortfolio",
        element: <UpdatePortfolioAdmin />,
      },
      {
        path: "all-team",
        element: <AllTeam />,
      },
      {
        path: "add-team",
        element: <AddTeam />,
      },
      {
        path: "update-team/:idteam",
        element: <UpdateTeamAdmin />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <ToastContainer />
    <RouterProvider router={router} />
  </>
);
