import React from "react";
import { useTranslation } from "react-i18next";
import SocialMedia from "./SocialMedia";

const Culture = () => {
  const { t } = useTranslation();

  return (
    <>
      <section>
        <div>
          <h2>{t("culture")}</h2>
          <p>{t("culture_p")}</p>
        </div>
      </section>

      <div className="iframe animate-element" data-aos="fade-up">
        <div
          style={{
            padding: "35% 0 0 0",
            position: "relative",
            maxWidth: "820px",
            margin: "auto",
          }}
        >
          <iframe
            style={{
              position: "absolute",
              top: "50%",
              left: "0",
              transform: "translateY(-50%)",
              width: "100%",
              aspectRatio: "2 / 1",
            }}
            src="https://www.youtube.com/embed/O9bKJ38n29g?si=-njCKMh9sxskkrck"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
        </div>
      </div>

      {/* <section>
        <div className="innovates">
          <div className="innovate">
            <h2>{t("innovate_1_1")}</h2>
            <p>{t("innovate_1_2")}</p>
          </div>
          <div className="innovate">
            <h2>{t("innovate_2_1")}</h2>
            <p>{t("innovate_2_2")}</p>
          </div>
        </div>
      </section> */}

      <div className="everyone">
        <div className="social_media_innovates">
          <SocialMedia />
        </div>
      </div>
    </>
  );
};

export default Culture;
