import React, { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { handleSubmitAdmin } from "../hook/Functions";

const LoginAdmin = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    handleSubmitAdmin(e, "login")
      .then((response) => {
        if (response.data.token) {
          toast.success("success login");

          localStorage.setItem("token", response.data.token);

          navigate("/dashboard");
        } else {
          toast.error("error login");
          console.log("Response:", response);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error(error.request.statusText);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="form-container">
      <div className="logo-container">Login Admin</div>

      <form className="form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Enter your email"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            name="password"
            placeholder="Enter your password"
            required
          />
        </div>

        <button className="form-submit-btn" type="submit" disabled={loading}>
          submit
        </button>
      </form>
    </div>
  );
};

export default LoginAdmin;
