import React, { useEffect, useState } from "react";
import { handleDelete, handleShow } from "../../../hook/Functions";
import { toast } from "react-toastify";
import Loading from "./../../../components/Loading";
import { useNavigate } from "react-router-dom";
import { FaRegEdit, FaWindowClose } from "react-icons/fa";

const AllPortfolio = () => {
  const [loading, setLoading] = useState(false);
  const [dataPortfolio, setDataPortfolio] = useState([]);
  const navigate = useNavigate();

  const TruncateText = ({ text }) => {
    const truncate = (htmlString, maxLength) => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlString, "text/html");
      const textContent = doc.body.textContent || "";

      return textContent.length > maxLength
        ? textContent.substring(0, maxLength) + "..."
        : textContent;
    };

    return <p>{truncate(text, 100)}</p>;
  };

  const handleShowLoading = () => {
    setLoading(true);
    handleShow("portfolio")
      .then((response) => {
        setDataPortfolio(response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error(error.request.statusText);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    handleShowLoading();
  }, []);

  const handleDeleteItem = (id) => {
    setLoading(true);
    handleDelete("portfolio", id)
      .then((response) => {
        if (response.data.success) {
          handleShowLoading();
          toast.success(response.data.success);
        } else {
          toast.error(response.data.error);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error(error.request.statusText);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleUpdateItem = (id) => {
    navigate(`/dashboard/update-portfolio/${id}`);
  };

  return (
    <section id="portfolio">
      {loading ? (
        <Loading />
      ) : (
        <div className="data_portfolio">
          {dataPortfolio && dataPortfolio.length > 0 ? (
            dataPortfolio.map((item, i) => {
              return (
                <div className="portfolio" key={i}>
                  <div className="image">
                    <img loading="lazy" src={item.image} alt={item.title_ar} />
                  </div>

                  <h2>{item.title_ar}</h2>

                  <TruncateText text={item.description_ar} />

                  <h2>{item.title_en}</h2>

                  <TruncateText text={item.description_en} />

                  <div className="all_button">
                    <FaRegEdit onClick={() => handleUpdateItem(item.id)} />

                    <FaWindowClose
                      disabled={loading}
                      onClick={() => handleDeleteItem(item.id)}
                    />
                  </div>
                </div>
              );
            })
          ) : (
            <p>No Data</p>
          )}
        </div>
      )}
    </section>
  );
};

export default AllPortfolio;
