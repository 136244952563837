import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { handleDelete, handleShow } from "../../../hook/Functions";
import Loading from "../../../components/Loading";
import { FaRegEdit, FaWindowClose } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import AddSliderAdmin from "./AddSliderAdmin";

const AllSliderAdmin = () => {
  const [loading, setLoading] = useState(false);
  const [dataSlider, setDataSlider] = useState([]);

  const navigate = useNavigate();

  const handleShowLoading = () => {
    setLoading(true);
    handleShow("show-slider")
      .then((response) => {
        setDataSlider(response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error(error.request.statusText);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    handleShowLoading();
  }, []);

  const handleDeleteItem = (id) => {
    setLoading(true);
    handleDelete("destroy-slider", id)
      .then((response) => {
        if (response.data.success) {
          handleShowLoading();
          toast.success(response.data.success);
        } else {
          toast.error(response.data.error);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error(error.request.statusText);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleUpdateItem = (id) => {
    navigate(`/dashboard/update-Slider/${id}`);
  };

  return (
    <>
      <AddSliderAdmin handleShowLoading={handleShowLoading} />

      <div className="all_slider_admin">
        {loading ? (
          <Loading />
        ) : dataSlider && dataSlider.length > 0 ? (
          dataSlider.map((item, i) => (
            <div className="slider_admin" key={i}>
              <img loading="lazy" src={item.image} alt={item.title_en} />

              <p>{item.title_en}</p>
              <p>{item.title_ar}</p>
              <p>{item.description_en}</p>
              <p>{item.description_ar}</p>

              <div className="all_button">
                <FaRegEdit onClick={() => handleUpdateItem(item.id)} />

                <FaWindowClose
                  disabled={loading}
                  onClick={() => handleDeleteItem(item.id)}
                />
              </div>
            </div>
          ))
        ) : (
          <p>No Data</p>
        )}
      </div>
    </>
  );
};

export default AllSliderAdmin;
