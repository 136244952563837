import React, { useCallback, useEffect, useRef, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import logo from "../components/images/logo.webp";
import { IoMdArrowDropright, IoMdArrowDropleft } from "react-icons/io";
import { useTranslation } from "react-i18next";
import { handleShow } from "../hook/Functions";
import { toast } from "react-toastify";
import { NavLink } from "react-router-dom";

const Slider = () => {
  const { i18n } = useTranslation();
  const [slider, setSlider] = useState();
  const [sliderMap, setDataSlider] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const nodeRef = useRef(null);
  const isDragging = useRef(false);
  const intervalId = useRef(null);
  const [bgFalse, setBgFalse] = useState(false);

  const [viewportHeight, setViewportHeight] = useState(
    window.innerHeight * 0.8
  );
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);

  const updateHeight = useCallback(() => {
    setViewportHeight(window.innerHeight * 0.8);
    setViewportWidth(window.innerWidth);

    if (viewportWidth >= 768) {
      setBgFalse(true);
    } else {
      setBgFalse(false);
    }
  }, [viewportWidth]);

  const handleShowLoading = useCallback(() => {
    handleShow("show-slider")
      .then((response) => {
        setDataSlider(response.data);
        setSlider(response.data[0]);
        updateHeight();
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error(error.request.statusText);
      });
  }, [updateHeight]);

  useEffect(() => {
    handleShowLoading();
  }, [handleShowLoading]);

  useEffect(() => {
    intervalId.current = setInterval(() => {
      if (!isDragging.current) {
        setCurrentIndex((prevIndex) => {
          const newIndex = (prevIndex + 1) % sliderMap.length;
          setSlider(sliderMap[newIndex]);
          return newIndex;
        });
      }
    }, 5000);

    return () => clearInterval(intervalId.current);
  }, [sliderMap]);

  const handlePrev = useCallback(() => {
    isDragging.current = true;
    clearInterval(intervalId.current);

    setCurrentIndex((prevIndex) => {
      const newIndex = (prevIndex - 1 + sliderMap.length) % sliderMap.length;
      setSlider(sliderMap[newIndex]);
      return newIndex;
    });
  }, [sliderMap]);

  const handleNext = useCallback(() => {
    isDragging.current = true;
    clearInterval(intervalId.current);

    setCurrentIndex((prevIndex) => {
      const newIndex = (prevIndex + 1) % sliderMap.length;
      setSlider(sliderMap[newIndex]);
      return newIndex;
    });
  }, [sliderMap]);

  const ChangeEN = () => {
    i18n.changeLanguage("en");
  };

  const ChangeAR = () => {
    i18n.changeLanguage("ar");
  };

  const handleMouseEnter = () => {
    isDragging.current = true;
  };

  const handleMouseLeave = () => {
    isDragging.current = false;
  };

  // console.log(sliderMap);

  return (
    <div
      style={{
        height: `${viewportHeight}px`,
      }}
      className="slider"
      id="home"
    >
      <div className="logo">
        <NavLink to="/">
          <img loading="lazy" src={logo} alt="logo" />
        </NavLink>

        {i18n.language === "ar" ? (
          <div className="changeLang" onClick={ChangeEN}>
            EN
          </div>
        ) : (
          <div className="changeLang" onClick={ChangeAR}>
            AR
          </div>
        )}
      </div>
      {sliderMap.length > 0 && (
        <TransitionGroup>
          <CSSTransition
            key={slider.id}
            timeout={500}
            classNames="fade"
            nodeRef={nodeRef}
          >
            <div
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onTouchStart={handleMouseEnter}
              onTouchEnd={handleMouseLeave}
              className="sliders"
              style={{
                height: `${viewportHeight}px`,
                background: bgFalse
                  ? `url(${slider.image}) center center / cover no-repeat fixed`
                  : "",
              }}
              ref={nodeRef}
            >
              {!bgFalse && (
                <img
                  src={slider.image}
                  alt={slider.title_en}
                  className="phone_slider"
                />
              )}

              <div className="text">
                <span></span>
                <h2>
                  {i18n.language == "ar" ? slider.title_ar : slider.title_en}
                </h2>
                <p>
                  {i18n.language == "ar"
                    ? slider.description_ar
                    : slider.description_en}
                </p>
              </div>
            </div>
          </CSSTransition>
        </TransitionGroup>
      )}

      <div className="custom-navigation">
        <IoMdArrowDropleft className="prev-arrow" onClick={handlePrev} />
        <IoMdArrowDropright className="next-arrow" onClick={handleNext} />
      </div>
    </div>
  );
};

export default Slider;
